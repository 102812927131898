@import './settings';

html {
  height: 100%;
  font-size: $fontSize;
}
body {
  height: 100%;
  background-color: $bg;
  font-family: $textFontFamily;
  font-size: $fontSize;
  color: $fontBlack;
}

// @media screen and (max-width: $fontSmallBreakpoint) {
//   html,
//   body {
//     font-size: $fontSmaller;
//   }
// }
// @media screen and (min-width: $fontLargeBreakpoint) {
//   html,
//   body {
//     font-size: $fontLarge;
//   }
// }

.appRoot {
  // height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFontFamily;
  font-weight: 900;
  font-style: normal;
  font-size: 1.25rem;
}

button:disabled {
  cursor: default;
}

.btn-outline-dark {
  &:hover {
    background-color: #252525;
    border-color: #252525;
  }
}
